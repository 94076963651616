@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

/* @font-face {
	font-family: "NotoSansKR";
	src: url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap");
	font-style: normal;
}

@font-face {
	font-family: "NotoSansKR";
	src: url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
	unicode-range: U+0020-007E;
	font-style: normal;
} */
/* 위에 두개는 유니코드 레인지에 따라 폰트 변경할때 쓰는 방법 */

body {
  font-family: 'Noto Sans KR', 'Montserrat', sans-serif;
  /* 폰트 바꾸려면 theme.js 파일에서도 수정해줘야 함 */
  background-color: #f8f9fb;
}

pre {
  font-family: 'Noto Sans KR', 'Montserrat', sans-serif;
}

.mont {
  font-family: 'Montserrat', 'Noto Sans KR', sans-serif;
  font-weight: 700;
}

.slick-prev {
  left: 0rem;
  height: 100%;
  display: flex;
  align-items: center;
  width: 6rem;
  justify-content: center;
  z-index: 1;
}
.slick-prev svg {
  margin-left: 0.8rem;
}
.slick-next {
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  width: 6rem;
  justify-content: center;
  z-index: 1;
}
.slick-prev::before,
.slick-next::before {
  display: none;
}
.slick-prev:hover,
.slick-next:hover {
  background-color: #ffffff17;
}
.mobile .slick-prev,
.mobile .slick-next {
  width: 3rem;
}

.slick-dots li button:before {
  font-size: 13px;
}

.slick-dots-vertical {
  position: absolute;
  /* top: 50%; */
  /* transform: translateY(-50%); */
  bottom: 0;
  right: -30px;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots-vertical li {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots-vertical li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}
.slick-dots-vertical li button:before {
  font-size: 13px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '●';
  text-align: center;
  opacity: 0.25;
  color: #000;
}
.slick-dots-vertical li.slick-active button:before {
  opacity: 0.75;
  color: #000;
}

.ani_container {
  perspective: 1000px;
}
.transition-500 {
  transition: all 0.5s ease;
}
/* .card {
	max-width: 20rem;
	min-width: 18rem;
} */
.ani_card .MuiLink-root:hover .rounded {
  background: #ab39ff;
  color: #fff;
}
.snsBox .MuiButton-outlinedPrimary:hover {
  border: 1px solid #ab39ff;
  background-color: rgba(173, 57, 255, 0.075);
  color: #5b148d;
}

.youtube_share {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

@media screen and (min-width: 600px) {
  .ani_card {
    transform-style: preserve-3d;
  }
  .card_img {
    -webkit-filter: grayscale(40%);
    filter: grayscale(40%);
  }
  .ani_card:hover .card_img {
    -webkit-filter: grayscale(0%);
    filter: none;
  }
  .go_arrow {
    filter: brightness(0);
  }
  .go_link:hover .go_arrow {
    filter: brightness(1);
  }
  .youtube_share:hover {
    -webkit-filter: grayscale(0%);
    filter: none;
  }
}

/*모바일 공지 핵 */
.article .MuiAccordionSummary-content {
  width: 30px;
  margin: 0px 0px;
}
.article .MuiAccordionSummary-root {
  padding: 0px 12px 1px 0px;
  min-height: 0;
  overflow: hidden;
}
.article .MuiAccordionSummary-root.Mui-expanded {
  min-height: 0px;
}
.article .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0;
}
.article .MuiAccordionDetails-root {
  display: flex;
  padding: 0px 5px 0px 0px;
}
.article .MuiAccordionSummary-root .MuiIconButton-root {
  padding: 0;
}
.article.MuiPaper-root {
  background-color: inherit;
}

/** utility **/
.utilityWrap {
  margin: 0 auto;
  font-size: 17px;
  text-align: left;
}
.utilityWrap h3 {
  padding: 31px 0 25px;
  font-size: 17px;
}
.utilityWrap h3 .red {
  color: #ff0000;
}
.utilityWrap h3,
.utilityWrap h4 {
  font-weight: bold;
  padding-bottom: 0.5rem;
}
.utilityWrap li {
  padding-left: 1rem;
}
.utilityWrap .utilityList h4 {
  font-size: 17px;
  padding-top: 26px;
}
.utilityWrap .utilityList a:hover {
  text-decoration: underline;
}
#container.page .title {
  position: relative;
  margin-bottom: 43px;
  font-weight: bold;
  font-size: 35px;
  text-align: center;
}
#container.page .title:after {
  position: absolute;
  left: 50%;
  bottom: -30px;
  content: '';
  width: 50px;
  height: 2px;
  background: #aa3aff;
  transform: translateX(-50%);
}

.sign_out_ul li {
  border-bottom: 0.5px solid #aaa;
  padding: 8px 0;
  margin-bottom: 5px;
}
.sign_out_ul li:last-child {
  border-bottom: 0.5px solid #888;
  padding: 8px 0 20px;
  margin-bottom: 15px;
}

/* 테이블 */

.MuiDataGrid-root {
  font-size: 12px !important;
}
.MuiDataGrid-colCellTitle {
  margin: 0 auto;
}
.MuiDataGrid-colCell,
.MuiDataGrid-cell {
  padding: 0 !important;
  text-align: center !important;
}
.MuiDataGrid-menuIcon {
  display: none !important;
}
.MuiDataGrid-colCellWrapper .MuiDataGrid-columnSeparator:last-child {
  display: none !important;
}

.inputPhone input::placeholder {
  height: 15px;
  padding: 8px 0;
  /* text-align: center; */
  font-size: 15px;
  line-height: 15px;
  -webkit-appearance: none;
  vertical-align: middle;
}
/* .inputPhone input::-webkit-input-placeholder {
	font-size: 15px;
	color: rgba(122, 46, 46, 0.4);
	text-transform: uppercase;
	vertical-align: middle;
} */

.fd2 button.MuiButtonBase-root {
  outline: 0;
}
.fd2 .selectOS .MuiRadio-colorSecondary.Mui-checked {
  color: #f4aa2f;
}
.fd2 .selectOS .MuiRadio-colorPrimary.Mui-checked {
  color: #f4aa2f;
}
.fd2 .selectOS .MuiRadio-root {
  color: #fff;
}

.event2 .MuiSlider-root > .MuiSlider-mark:last-child {
  color: #fa0;
  opacity: 0;
}

.preregister .Mui-disabled img {
  opacity: 0.5;
}
.preregister .Mui-disabled .authNumber {
  opacity: 0.5;
}

.balloon_03 {
  position: relative;
  text-align: center;
  min-width: 270px;
  height: 40px;
  padding: 7px;
  background: #fff;
  border-radius: 10px 5px 10px 10px;
}
.balloon_03:after {
  border-top: 15px solid #fff;
  border-left: 0px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 0px solid transparent;
  content: '';
  position: absolute;
  top: 3px;
  right: -13px;
}

.eventCoverBox {
  border: 6px solid #f4aa2f;
  font-weight: 900;
  box-shadow: 10px 10px 3px rgba(0, 0, 0, 0.2);
  color: #fff;
}
.eventCoverBox h1 {
  font-size: 2.5rem;
}
.eventCoverBox h2 {
  font-size: 1.6rem;
}
.eventCoverBox .bottomBox {
  margin: -0.25rem;
  background: #f4aa2f;
  color: black;
  font-weight: bold;
  text-shadow: 1px 1px 2px #0006;
}
.eventCoverBox b {
  color: #f4aa2f;
}
.eventCoverBox h1,
.eventCoverBox h2 {
  text-shadow: 2px 2px 2px #000, 2px -2px 2px #000, -2px 2px 2px #000,
    -2px -2px 2px #000, 2px 0px 2px #000, 0px 2px 2px #000, -2px 0px 2px #000,
    0px -2px 2px #000, 3px 3px 2px #000, 3px -3px 2px #000, -3px 3px 2px #000,
    -3px -3px 2px #000, 3px 0px 2px #000, 0px 3px 2px #000, -3px 0px 2px #000,
    0px -3px 2px #000, 4px 4px 2px #000, 4px -4px 2px #000, -4px 4px 2px #000,
    -4px -4px 2px #000, 4px 0px 2px #000, 0px 4px 2px #000, -4px 0px 2px #000,
    0px -4px 2px #000, 0 -1px 0 #000, 0 1px 0 #000, 0 2px 0 #000, 0 3px 0 #000,
    0 4px 0 #000, 0 5px 0 #000, 0 6px 0 #000, 0 7px 0 #000, 0 8px 0 #000,
    0 9px 0 #000, 0 10px 0 #000;
}

.eventCoverBox .stroke {
  text-shadow: 3px 3px 2px #000, 3px -3px 2px #000, -3px 3px 2px #000,
    -3px -3px 2px #000, 3px 0px 2px #000, 0px 3px 2px #000, -3px 0px 2px #000,
    0px -3px 2px #000;
}

@media screen and (max-width: 640px) {
  .eventCoverBox h1 {
    font-size: 1.5rem;
  }
  .eventCoverBox h2 {
    font-size: 1rem;
  }
}
