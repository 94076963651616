@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	@variants responsive {
		.scroll-snap-none {
			scroll-snap-type: none;
		}
		.scroll-snap-x {
			scroll-snap-type: x;
		}
		.scroll-snap-y {
			scroll-snap-type: y;
		}
		.w-70 {
			width: 17rem;
		}
	}
	/* not last but dark */
	/* @variants last {
		.filter-none {
			filter: none;
		}
		.filter-grayscale {
			filter: grayscale(100%);
		}
	} */
}
html {
	/* overflow: hidden; */
	overscroll-behavior: contain;
}

body {
	overscroll-behavior: contain;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
::selection {
	color: #fff;
	background: #ab39ff;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
